import React from 'react'
import { SortDirection } from '../../../hooks/useFilters/useFilters.type'
import { TableSortHeaderProps } from './types'

export const TableSortHeader = ({
   header,
   id,
   activeSortHeader,
   handleHeaderSortClick,
}: TableSortHeaderProps) => {
   const isActiveAsc =
      activeSortHeader.sortBy.toLowerCase() === id.toLowerCase() &&
      activeSortHeader.sortDirection === SortDirection.ASCENDING
   const isActiveDesc =
      activeSortHeader.sortBy.toLowerCase() === id.toLowerCase() &&
      activeSortHeader.sortDirection === SortDirection.DESCENDING

   return (
      <>
         <div
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               flexFlow: 'nowrap',
            }}
         >
            <span>{header}</span>
            <span
               style={{ float: 'right', marginTop: '5px', cursor: 'pointer' }}
            >
               <div
                  style={{
                     borderBottom: isActiveAsc
                        ? '6px solid #ffc107'
                        : '6px solid #D1D7DB',
                     width: 0,
                     height: 0,
                     borderRadius: '50%',
                     borderLeft: '8px solid transparent',
                     borderRight: '8px solid transparent',
                     marginBottom: '2px',
                  }}
                  onClick={() =>
                     handleHeaderSortClick(id, SortDirection.ASCENDING)
                  }
               ></div>
               <div
                  style={{
                     borderTop: isActiveDesc
                        ? '6px solid #ffc107'
                        : '6px solid #D1D7DB',
                     width: 0,
                     height: 0,
                     borderRadius: '50%',
                     borderLeft: '8px solid transparent',
                     borderRight: '8px solid transparent',
                  }}
                  onClick={() =>
                     handleHeaderSortClick(id, SortDirection.DESCENDING)
                  }
               ></div>
            </span>
         </div>
      </>
   )
}
